/* eslint-disable arrow-body-style */
/* This is the Root component mainly initializes Redux and React Router. */
import eurekaMgrs from '@eureka/ui-managers';
import '@eureka/ui-managers/src/styles/layout.css';
import '@ui5/webcomponents-icons/dist/Assets-static';
import { MessageStrip } from '@ui5/webcomponents-react/dist/MessageStrip';
import { MessageStripType } from '@ui5/webcomponents-react/dist/MessageStripType';
import { ThemeProvider } from '@ui5/webcomponents-react/dist/ThemeProvider';
import '@ui5/webcomponents/dist/features/InputSuggestions.js';
import eureka from 'eureka';
import React, { useEffect, useRef, useState } from 'react';
import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';

import { listenToEventBus, mergeSettings, renderRouteConfigV3 } from './App.helper';
import history from './common/history';
import routeConfig from './common/routeConfig';
import store from './common/store';
import { getURLParam, TestingLocales } from './common/Utils';
import { MicroFrontend } from './features/common';
import { getRandom } from './features/common/Utils';
import WebAssistant from './common/web-assistant/WebAssistant';
import { setDocumentLang } from './common/Utils';

const { MessageToast } = eureka.controls;
const { initI18n, setLanguage } = eureka.I18nProvider;
const { Spinner } = eureka.components;
const { addConfig, getConfig, setCsrfToken, updateFeatureToggle } = eurekaMgrs.ConfigManager;
const configManagerSetLanguage = eurekaMgrs.ConfigManager.setLanguage;
const configManagerGetLanguage = eurekaMgrs.ConfigManager.getLanguage;

const { eventBus } = eurekaMgrs;

let config = null;
let lng = 'en-US';

export const loader = () => {
  return <div>Loading...</div>;
};

// eslint-disable-next-line arrow-body-style
export const renderError = (msg) => {
  return (
    <MessageStrip
      style={{ marginTop: '10px', marginRight: '10px' }}
      type={MessageStripType.Negative}
      icon="error"
      noIcon={false}
      noCloseButton
      onClose={() => {}}
    >
      {msg}
    </MessageStrip>
  );
};

export const MicroFrontendWrapper = ({ history, match, host, name, settings, user }) => {
  if (!settings) {
    console.error('Settings for microfrontends is empty, which is not allowed');
    return null;
  }
  return (
    <MicroFrontend
      history={history}
      match={match}
      host={host}
      name={name}
      config={config}
      settings={settings}
      user={user.current}
      eventBus={eventBus}
    />
  );
};

export const renderMicroFrontendRoutes = ({ mfdRouters, history, settings, user }) => {
  const routes = [];
  mfdRouters.forEach((app) => {
    app.routers.forEach((route) => {
      routes.push(
        <Route
          key={route + getRandom()}
          exact
          path={route}
          component={(props) => (
            <MicroFrontendWrapper
              {...props}
              host={app.host}
              name={app.name}
              history={history}
              settings={settings}
              user={user}
            />
          )}
        />,
      );
    });
  });
  return routes;
};

const onFetchConfigSuccess = ({ manifest, state, setState, setMicroFrontends }) => {
  const shell = eureka.I18nProvider.getLocation(manifest, manifest['shell-ui']);
  const microFrontends = [];

  manifest.components.forEach((component) => {
    const host = eureka.I18nProvider.getLocation(manifest, component);

    microFrontends.push({
      name: component.config.app,
      host,
      routers: component.config.routers,
    });
  });

  config = manifest;
  setState({
    ...state,
    config,
  });

  setMicroFrontends(microFrontends);

  // add app config into config manager
  addConfig('appConfig', config);

  // init i18n
  // i18next configuration: https://www.i18next.com/overview/configuration-options
  initI18n(
    {
      shell,
    },
    {
      debug: process.env.NODE_ENV === 'production',
      lowerCaseLng: false,
      fallbackLng: 'en-US',
      fallbackNS: 'shell',
      whitelist: false,
      lng, // en-US en-US-sappsd
      load: 'currentOnly',
      defaultNS: 'shell',
      ns: 'shell',
      preload: [lng], // en-US en-US-sappsd
      react: {
        useSuspense: false,
        wait: false,
      },
    },
  );

  // Handle error page
  if (window.location.pathname.startsWith('/error')) {
    return setState({
      ...state,
      initializing: false,
    });
  }
};

const renderInitializing = () => {
  return (
    <div
      className="app-loading"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <Spinner cssClass="page-load-spinner" />
    </div>
  );
};

const onFetchAuthSuccess = ({ auth, user }) => {
  window.hasLoggedin = true;
  if (auth?.data) {
    addConfig('user', auth?.data);
  }
  user.current = auth?.data;
};

const onFetchCsrfFailed = ({ error, user, state, setState }) => {
  MessageToast.error('Failed to get CSRF token, please contact System Administrator!');
  console.log(`${error}`);
  // set a fake csrf token
  setCsrfToken('fakecsrftoken');
  setState({
    ...state,
    settings: {},
    user: user.current,
    fetchConfigError: null,
  });
};

export const onFetchSettingsFinally = ({ rawSettings, state, setState }) => {
  const testingLng = getURLParam(window.location.search, 'sap-language');
  if (testingLng) {
    lng = TestingLocales[testingLng] ? TestingLocales[testingLng] : testingLng;
  }

  // set new lng
  setLanguage(lng);
  configManagerSetLanguage(lng);
  setDocumentLang(document, lng);
  if (Object.keys(rawSettings.current?.basicSetup) <= 0) {
    setTimeout(() => {
      MessageToast.error('Shell_LoadSettingFailed');
    }, 0);
  }
  // set initialization done
  setState((prevState) => ({
    ...prevState,
    initializing: false,
  }));
};

const getDataFromResults = ({ results, index, defValue = {} }) => {
  return Array.isArray(results) && results.length > index && results[index]?.data
    ? results[index]?.data
    : defValue;
};

const onFetchSettingsSuccess = ({ results, rawSettings, state, setState, user }) => {
  const userProfile = getDataFromResults({ results, index: 0 });
  // If profileTimeZone is null, use UTC-08:00 Pacific Time (US & Canada) -- America/Los_Angeles as default time zone
  userProfile.timeZone = userProfile?.profileTimeZone || 'America/Los_Angeles';
  rawSettings.current = {
    ...rawSettings.current,
    basicSetup: getDataFromResults({ results, index: 0 }),
    userProfile,
    companyProfile: getDataFromResults({ results, index: 1 }),
  };
  const settings = mergeSettings(rawSettings.current);
  // TODO: after basicSetup, userProfile, companyProfile initialized, then change index from 0 to 3
  const currentUser = getDataFromResults({ results, index: 0 });
  const currentUserPermissions = getDataFromResults({ results, index: 1 });

  addConfig(
    'CurrentUserPermissions',
    (currentUserPermissions?.roles || []).map((p) => p.toUpperCase()),
  );
  addConfig('CurrentUser', currentUser);
  updateFeatureToggle(getDataFromResults({ results, index: 2 })?.resultList);

  if (rawSettings.current.userProfile && rawSettings.current.userProfile.language) {
    lng = rawSettings.current.userProfile.language;
  } else if (rawSettings.current.basicSetup && rawSettings.current.basicSetup.language) {
    lng = rawSettings.current.basicSetup.language;
  }

  user.current.databaseUserId = rawSettings.current.basicSetup.id;
  setState({
    ...state,
    settings,
    user: rawSettings.current.userProfile,
  });
};

export const onFetchAuthFailed = ({ error, state, setState }) => {
  if (window.location.href.indexOf('/login') < 0 && error.request.status === 401) {
    window.location.href = '/login?application=irss';
  } else if (window.location.href.indexOf('/login') < 0 && error.request.status !== 401) {
    window.hasLoggedin = false;
    setState({ ...state, authUserError: error });
  } else {
    window.hasLoggedin = false;
    console.log(`Auth user error: ${error}`);
    setState({
      ...state,
      initializing: false,
    });
  }
};

export const renderMfes = ({ state, user, microFrontends }) => {
  const containerRoutes = renderRouteConfigV3(routeConfig, '/', config, state.settings, user);
  const microFrontendRoutes = renderMicroFrontendRoutes({
    mfdRouters: microFrontends,
    history,
    settings: state.settings,
    user,
  });
  return (
    <ThemeProvider>
      <Provider store={store}>
        <Router history={history}>
          <WebAssistant lang={configManagerGetLanguage() || 'en-US'} />
          <Switch multiple={5}>
            {microFrontendRoutes}
            {containerRoutes}
          </Switch>
        </Router>
      </Provider>
    </ThemeProvider>
  );
};

const App = ({ fetchConfig, fetchAuth, fetchCsrf, fetchSettings, fetchUserCreate }) => {
  const [state, setState] = useState({
    initializing: true,
    fetchConfigError: false,
    fetchSettingsError: false,
    authUserError: false,
    config: null,
    settings: { basicSetup: {}, userProfile: {}, companyProfile: {} },
    user: {},
  });
  const [microFrontends, setMicroFrontends] = useState([]);

  const user = useRef(null);
  const rawSettings = useRef({ basicSetup: {}, userProfile: {}, companyProfile: {} });

  useEffect(() => {
    listenToEventBus({ rawSettings, state, setState, history });
    addConfig('application', 'irss');
    fetchConfig().then(
      (result) => {
        const manifest = result.data;
        onFetchConfigSuccess({
          manifest,
          state,
          setState,
          setMicroFrontends,
        });
        fetchAuth().then(
          (auth) => {
            onFetchAuthSuccess({ auth, user });
            fetchCsrf()
              .then(
                (result) => setCsrfToken(result?.data?.token),
                (error) => onFetchCsrfFailed({ error, user, state, setState }),
              )
              .finally(() => {
                fetchUserCreate().then(
                  ({ data }) => {
                    addConfig('user', Object.assign({}, data, auth?.data));
                    user.current = getConfig('user');
                    fetchSettings()
                      .then(
                        (results) => {
                          onFetchSettingsSuccess({
                            results,
                            state,
                            setState,
                            rawSettings,
                            user,
                          });
                        },
                        () =>
                          setState({
                            ...state,
                            settings: {},
                            user: user.current,
                            fetchConfigError: false,
                          }),
                      )
                      .finally(() => onFetchSettingsFinally({ rawSettings, state, setState }));
                  },
                  () =>
                    setState({
                      ...state,
                      settings: {},
                      user: user.current,
                      fetchConfigError: false,
                    }),
                );
              });
          },
          (error) => onFetchAuthFailed({ error, state, setState }),
        );
      },
      (error) => {
        console.error('Error:', error);
        setState({
          ...state,
          initializing: false,
          fetchConfigError: error,
        });
      },
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.fetchConfigError) {
    return renderError('Failed to load config, please try again.', state.fetchConfigError);
  }

  if (state.authUserError) {
    return renderError('Failed to get user information, please refresh page.', state.authUserError);
  }

  if (state.fetchSettingsError) {
    return renderError(
      'Failed to get company or user settings, please refresh page.',
      state.fetchSettingsError,
    );
  }

  if (state.initializing) {
    return renderInitializing();
  }

  return renderMfes({ state, user, microFrontends });
};

export default App;
