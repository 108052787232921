import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { MessageStrip } from '@ui5/webcomponents-react/dist/MessageStrip';
import { Header, SidePanel } from '../common';
import Tiles from './Tiles';
import { ShellDialog } from '../plugins/dialog.plugin';
import MessageToast from '../plugins/toast.plugin';
import EurekaComponents from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';
import { filterWithFeatureFlags } from '../../common/Utils';
// import * as commonActions from '../common/redux/actions';
// import { handleSidenav } from '../common/Header';

const { getConfig } = eurekaMgrs.ConfigManager;
const { Spinner } = EurekaComponents.components;
const { OptionalMessageBox } = EurekaComponents.controls;

// // import eureka from '@eureka/ui-managers'
// const { i18n, getCurrentLanguage, withTranslation } = EurekaComponents.I18nProvider;

const navigatePage = (props, mfe, router) => {
  if (
    props.config.components &&
    (props.config.components || []).filter((c) => c.name === mfe).length > 0
  ) {
    props.history.push(router);
  }
};

export class WelcomePage extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    eventBus: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const permissions = getConfig('CurrentUserPermissions') || [];
    // console.log(permissions);
    if (
      permissions?.length > 0 &&
      ['USERSANDROLEPERMISSION.READ'].every((p) => permissions.includes(p))
    ) {
      // Admin role
      navigatePage(this.props, 'kpi-library-ui', '/dashboard/KAMDashboard');
    } else if (
      permissions?.length > 0 &&
      ['VISITEXECUTION.READ', 'VISITPLANNING.READ'].every((p) => permissions.includes(p))
    ) {
      // Sales rep role commonActions?.hideMenu?.call();
      navigatePage(this.props, 'ome-visit-planning-ui', '/visit-planning/home');
    } else {
      // Key account manager role
      // navigatePage(this.props, 'activity-planning-ui', '/activity-planning/list');
      // navigatePage(this.props, 'kpi-library-ui', '/dashboard/KAMDashboard');
      navigatePage(this.props, 'irmo-provisioning-ui', '/irmo-provisioning/integration-config');
    }
  }

  renderError(err) {
    return (
      <MessageStrip className="" slot="" style={{}} tooltip="">
        {err}
      </MessageStrip>
    );
  }

  render() {
    const { showMenu } = this.props.common;
    // const { t } = this.props;
    // console.log(i18n);
    // console.log(`Current language is ${getCurrentLanguage()}`);

    return (
      <div className="fd-shell fd-shell--fundamentals">
        <div className="fd-shell__header">
          <Suspense fallback={<Spinner />}>
            <Header
              history={this.props.history}
              config={this.props.config}
              settings={this.props.settings}
              eventBus={this.props.eventBus}
              user={this.props.user}
            />
          </Suspense>
        </div>
        <div className="fd-shell__app">
          <div className="fd-app">
            <div
              className={
                `fd-app__navigation fd-app__navigation--vertical${
                 showMenu ? ' show-menu' : ' hide-menu'}`
              }
            >
              <Suspense fallback={<Spinner />}>
                <SidePanel
                  history={this.props.history}
                  match={this.props.match}
                  config={filterWithFeatureFlags(this.props.config)}
                />
              </Suspense>
            </div>
            <main className="fd-app__main">
              <Suspense fallback={<Spinner cssClass="page-load-spinner" />}>
                <div className="home-page">
                  <Tiles history={this.props.history} config={this.props.config} />
                </div>
              </Suspense>
            </main>
          </div>
        </div>
        <ShellDialog key="welcome-page-dialog" />
        <MessageToast key="welcome-page-toast" />
        <OptionalMessageBox />
        {/* <div className="fd-shell__footer"><span>SAP</span></div> */}
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

// export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WelcomePage));
export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage);
