import { axiosCreate } from './eureka';

export const usersAxios = axiosCreate({
  baseURL: '/api/user-configuration-plus/business/v1/users',
}).instance;

export const setupsAxios = axiosCreate({
  baseURL: '/api/rm-company-management/business/v1/setups',
}).instance;

export const companiesAxios = axiosCreate({
  baseURL: '/api/rm-company-management/business/v1/companies',
}).instance;

export const languagesAxios = axiosCreate({
  baseURL: '/api/ome-user/business/v1/current-language-setting',
}).instance;

export const fetchMe = async () => usersAxios.get('/me');

export const patchMe = async (payload) => usersAxios.patch('/me', payload);

export const fetchBasicSetup = async () => setupsAxios.get('/basic-setup');

export const fetchLanguages = async (currentLang) => languagesAxios.get(`/${currentLang}`);
