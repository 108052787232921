/* eslint-disable @typescript-eslint/no-unused-vars */
import eureka from '@eureka/ui-components/index.esm.js';
import {
  FETCH_USER_PERMISSION_BEGIN,
  FETCH_USER_PERMISSION_SUCCESS,
  FETCH_USER_PERMISSION_FAILURE,
  FETCH_USER_PERMISSION_DISMISS_ERROR,
} from './constants';
const { axiosCreate } = eureka.HttpWrapper;
const axiosWrapper = axiosCreate();
const axios = axiosWrapper.instance;

// Rekit uses redux-thunk for async actions by default: https://github.com/gaearon/redux-thunk
// If you prefer redux-saga, you can use rekit-plugin-redux-saga: https://github.com/supnate/rekit-plugin-redux-saga
export function fetchUserPermissions(args = {}) {
  return (dispatch) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: FETCH_USER_PERMISSION_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      // doRequest is a placeholder Promise. You should replace it with your own logic.
      // See the real-word example at:  https://github.com/supnate/rekit/blob/master/src/features/home/redux/fetchRedditReactjsList.js

      const permissionRequest = axios.get('/api/role-permission/business/v1/users/me');

      permissionRequest.then(
        (res) => {
          dispatch({
            type: FETCH_USER_PERMISSION_SUCCESS,
            data: res.data,
          });
          resolve(res.data);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: FETCH_USER_PERMISSION_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export function dismissFetchUserPermissionsError() {
  return {
    type: FETCH_USER_PERMISSION_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_USER_PERMISSION_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchUserPermissionsPending: true,
        fetchUserPermissionsError: null,
      };

    case FETCH_USER_PERMISSION_SUCCESS:
      // The request is success
      return {
        ...state,
        userPermissions: action.data,
        needReloadUserPermissions: false,
        fetchUserPermissionsPending: false,
        fetchUserPermissionsError: null,
      };

    case FETCH_USER_PERMISSION_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchUserPermissionsPending: false,
        fetchUserPermissionsError: action.data.error,
      };

    case FETCH_USER_PERMISSION_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchUserPermissionsError: null,
      };

    default:
      return state;
  }
}
