/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { Suspense } from 'react';
import eureka from 'eureka';
import { createUseStyles } from 'react-jss';
import logo from './ErrorBear';
import { Header, SidePanel } from '.';
import { ShellDialog } from '../plugins/dialog.plugin';
import { filterWithFeatureFlags } from '../../common/Utils';

const { Spinner } = eureka.components;
const { useTranslation } = eureka.I18nProvider;

export default (rowprops) => {
  const { t } = useTranslation();
  const { props, errorType, errorCode } = rowprops;

  const styles = () => ({
    errorPage: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    errorTitle: {
      fontWeight: 'normal',
      fontSize: '144px',
      lineHeight: '166px',
      color: '#a7a7a7',
      marginBottom: '17px',
      marginTop: '-3rem',
    },
    errorContent: {
      fontSize: '25px',
      lineHeight: '29px',
      color: '#a7a7a7',
    },
    dashboard: {
      color: '#0854A0',
      cursor: 'pointer',
    },
    errorMsg: {
      fontSize: '42px',
      fontWeight: 'bold',
      lineHeight: '48px',
      color: '#fff',
      margin: 0,
    },
    errorMsgWrapper: {
      position: 'absolute',
      top: '225px',
      left: '145px',
      textAlign: 'center',
    },
  });
  const useStyles = createUseStyles(styles);

  const classes = useStyles();

  const errorMapping = {
    500: {
      errorTitle: 'ErrorTips_Oops',
      errorContent: 'ErrorTips_500',
    },
    403: {
      errorTitle: 'ErrorTips_Wait',
      errorContent: 'ErrorTips_403',
    },
    404: {
      errorTitle: 'ErrorTips_Ohno',
      errorContent: 'ErrorTips_404',
    },
  };

  const errorInstance = errorMapping[errorType];

  return (
    <div className="fd-shell fd-shell--fundamentals">
      <div className="fd-shell__header">
        <Suspense fallback={<Spinner />}>
          <Header
            history={props.history}
            config={props.config}
            settings={props.settings}
            eventBus={props.eventBus}
            user={props.user}
          />
        </Suspense>
      </div>
      <div className="fd-shell__app">
        <div className="fd-app">
          <div>
            <Suspense fallback={<Spinner />}>
              <SidePanel history={props.history} match={props.match} config={filterWithFeatureFlags(props.config)} />
            </Suspense>
          </div>
          <main className="fd-app__main">
            <div className={classes.errorPage}>
              <div
                className="errorpage-wrapper"
                style={{
                  marginRight: '122px',
                  height: '259px',
                  textAlign: 'center',
                }}
              >
                <h1 className={classes.errorTitle}>{t(errorInstance.errorTitle)}</h1>
                <p className={classes.errorContent}>{t(errorInstance.errorContent)}</p>
                {errorType === '500' && (
                  <p className={classes.errorContent}>{t('ErrorTips_refresh')}</p>
                )}
                <p className={classes.errorContent}>
                  {t('ErrorTips_back')}
                  <span
                    className={classes.dashboard}
                    onClick={() => props.history.push('/analytics-dashboard')}
                  >
                    {t('ErrorTips_dashboard')}
                  </span>
                </p>
                {errorType === '500' && (
                  <p className={classes.errorContent} style={{ marginTop: '43px', width: '654px' }}>
                    {t('ErrorCode_code')}
                    {errorCode}
                  </p>
                )}
              </div>
              <div className="img-wrapper" style={{ position: 'relative', height: '545px' }}>
                <img alt="Error Logo" src={logo} width={452} height={545} />
                <div className={classes.errorMsgWrapper}>
                  <p className={classes.errorMsg}>{errorType}</p>
                  <p style={{ fontSize: '20px', color: '#fff', fontWeight: 'bold', margin: 0 }}>
                    {t('ErrorCode_error')}
                  </p>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <ShellDialog key="Error-page-dialog" />
    </div>
  );
};
