import React, { useEffect } from 'react';
import useWebAssistant from './useWebAssistant';
import eurekaMgrs from '@eureka/ui-managers';

const { eventBus } = eurekaMgrs;

const WebAssistant = (props) => {
  const { lang } = props;
  const { webAssistantLoaded } = useWebAssistant({ lang });
  useEffect(() => {
    if (webAssistantLoaded) {
      // istanbul ignore next
      eventBus.emit('wa-loaded', '');
    }
  }, [webAssistantLoaded]);
  return null;
};

export default WebAssistant;
