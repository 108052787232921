/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable operator-linebreak */
/* eslint-disable import/no-named-as-default */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-children-prop */
/* eslint-disable arrow-body-style */
import React, { useEffect, useRef, useState } from 'react';
import eurekaMgrs from '@eureka/ui-managers';
import { MessageStrip } from '@ui5/webcomponents-react/dist/MessageStrip';
import { MessageStripType } from '@ui5/webcomponents-react/dist/MessageStripType';
import eureka from 'eureka';
import { useLocation } from 'react-router';
import DefaultLayout from './DefaultLayout';
import MashupLayout from './MashupLayout';

const { ProxyHelper } = eurekaMgrs.ProxyManager;
const { fetchApplicationManifestAssets, renderApplication, unmountApplication, fetchManifest } =
  ProxyHelper;
const { getCurrentLanguage } = eureka.I18nProvider;
const { Spinner } = eureka.components;

const { UserPreferenceManager, eventBus } = eurekaMgrs;

export const hasLoggedin = () => {
  return !!window.hasLoggedin;
};

export const defaultGetAppPreference = (name) => {
  return Promise.resolve({
    data: {},
  });
};

export const renderApp = ({ history, match, host, config, name, user, settings, container }) => {
  renderApplication(
    name,
    history,
    {
      history,
      match,
      host,
      user,
      eventBus,
      config,
      settings,
    },
    container,
    config,
  );
  /* istanbul ignore next */
  // eventBus.emit('i18n-update', null, getCurrentLanguage());
};

export const renderMicroFrontend = ({
  history,
  match,
  host,
  config,
  name,
  user,
  settings,
  container,
  fetchManifestError,
  userPreferencePromise,
}) => {
  if (fetchManifestError) {
    return;
  }
  userPreferencePromise
    .then((res) => {
      UserPreferenceManager.setAppSetting(name, res.data);
    })
    .catch((err) => {
      /* istanbul ignore next */
      console.log('Load user preference error:', err);
    })
    .finally(() => {
      renderApp({ history, match, host, config, name, user, settings, container });
    });
};

export const renderContainer = ({ name, containerRef }) => {
  return (
    <div
      id="microfrontend-viewport"
      data-testid={`${name}-microfrontend-render-container`}
      style={{ height: '100%' }}
    >
      <div id={`${name.toLowerCase()}-container`} style={{ height: '100%' }}>
        <div
          id={`${name.toLowerCase()}-content`}
          className="microfrontent-content"
          ref={containerRef}
          style={{ height: '100%' }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <Spinner />
          </div>
        </div>
      </div>
    </div>
  );
};

export const renderError = () => {
  return (
    <MessageStrip
      icon="message-error"
      style={{ margin: '10px' }}
      noCloseButton={false}
      type={MessageStripType.Negative}
    >
      Failed to load asset manifest, please try again.
    </MessageStrip>
  );
};

/* istanbul ignore next */
const MicroFrontend = ({
  name,
  host,
  document,
  match,
  history,
  config,
  settings,
  user,
  getManifest = null,
  getAppPreference = null,
}) => {
  const [fetchManifestError, setFetchManifestError] = useState(false);
  const containerRef = useRef();
  const userPreferencePromise = (getAppPreference || defaultGetAppPreference)(name);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const isEmbed = query.get('embedded') === 'true';

  useEffect(() => {
    const containerCurrent = containerRef.current;
    if (name !== 'mfe-login' && !hasLoggedin()) {
      history.push('/login?application=irss');
    } else if (containerCurrent) {
      fetchManifest({ host, name, getManifest }).then((manifest) => {
        // manifest host is equal to `http://localhost:2022`
        fetchApplicationManifestAssets(
          manifest,
          manifest?.files['main.js']?.startsWith('http') ? '' : host,
          name,
          config,
        ).then(
          () => {
            renderMicroFrontend({
              history,
              match,
              host,
              config,
              name,
              user,
              settings,
              container: containerCurrent,
              fetchManifestError,
              userPreferencePromise,
            });
          },
          (err) => {
            console.log('Load main asset error:', err);
            setFetchManifestError(true);
          },
        );
      });
    }

    return () => {
      if (fetchManifestError) {
        return;
      }
      unmountApplication(name);
    };
  }, []);

  if (fetchManifestError) {
    return (
      <DefaultLayout
        match={match}
        children={renderError()}
        history={history}
        config={config}
        user={user}
        settings={settings}
      />
    );
  }

  const container = renderContainer({ name, containerRef });

  if (name.toLowerCase() === 'mfe-login') {
    return <div style={{ height: '100%' }}>{container}</div>;
  }

  if (isEmbed) {
    return (
      <MashupLayout
        children={container}
        match={match}
        history={history}
        config={config}
        settings={settings}
        user={user}
      />
    );
  }

  return (
    <DefaultLayout
      children={container}
      match={match}
      history={history}
      config={config}
      settings={settings}
      user={user}
    />
  );
};

export default MicroFrontend;
