/* eslint-disable @typescript-eslint/no-use-before-define */
import eurekaMgrs from '@eureka/ui-managers';
import EurekaComponents from 'eureka';
import { saveUserPreference, updateUserPreference } from './userPreferenceAxios';

const { OptionalMessageBox } = EurekaComponents.controls;

const { UserPreferenceManager } = eurekaMgrs;
const { eventBus } = eurekaMgrs;

export function confirm(config = {}) {
  checkConfig(config);

  const notShowObj = UserPreferenceManager.getValueObj(config.appName, config.preferenceKey);
  if (notShowObj === undefined || notShowObj.preferenceValue === 'false') {
    // undefined indicates the first time to invoke
    eventBus.emit('on-optional-message-box', '', {
      ...config,
      userPreferenceHandler,
    });
  } else if (typeof config.closeCallback === 'function') {
      config.closeCallback();
    }
}

function checkConfig(config) {
  const { appName, preferenceKey, content } = config;
  const validValue = /^[_a-zA-Z][_a-zA-Z0-9]{1,255}$/;
  if (appName && preferenceKey) {
    if (!validValue.test(appName)) {
      console.error('The property appName should follow identifier naming');
    }
    if (!validValue.test(preferenceKey)) {
      console.error('The property preferenceKey should follow identifier naming');
    }
  } else {
    console.error('The property appName or preferenceKey is not assigned');
  }

  if (!content) {
    console.error('The property content is not assigned');
  }
}

/* istanbul ignore next */
export function userPreferenceHandler(appName, preferenceKey, notShow) {
  const originalPreference = UserPreferenceManager.getValueObj(appName, preferenceKey);
  const notShowString = notShow.toString();
  if (originalPreference === undefined) {
    // first time to add the user preference
    const preference = {
      preferenceValue: notShowString,
    };
    saveUserPreference(appName, preferenceKey, preference)
      .then((res) => {
        UserPreferenceManager.setValueObj(appName, preferenceKey, res.data);
      })
      .catch((err) => {
        console.log('Failed to save user preference: ', err.response.data);
      });
  } else if (originalPreference.preferenceValue !== notShowString) {
    // update the user preference
    const preference = {
      preferenceValue: notShowString,
    };
    updateUserPreference(appName, preferenceKey, preference)
      .then((res) => {
        UserPreferenceManager.setValueObj(appName, preferenceKey, res.data);
      })
      .catch((err) => {
        console.log('Failed to update user preference: ', err.response.data);
      });
  }
}

OptionalMessageBox.confirm = confirm;

export default OptionalMessageBox;
