import React, { useEffect } from 'react';
import { Select, Option, Label } from '@ui5/webcomponents-react';
import { SegmentedButton, ToggleButton } from '@ui5/webcomponents-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { fetchTimeZone } from '../../common/redux/actions';
import {
  DateFormatOptions,
  DateFormatTexts,
  TimeFormatOptions,
  TimeFormatTexts,
  TimeFormatTextsDefualts,
} from './options';
import { useTranslation } from './eureka';

const formItemStyle = {
  width: '100%',
  margin: '15px auto',
  display: 'flex',
  flexDirection: 'column',
};

const labelStyle = {
  width: '100%',
  marginBottom: '4px',
};

const LanguageAndRegion = ({ data, languages, onDataPropChange, actions, common }) => {
  const { t } = useTranslation('shell');
  const { fetchTimeZone } = actions;
  const { timezone } = common;

  useEffect(() => {
    if (!timezone) {
      fetchTimeZone();
    }
  }, []);

  return (
    <div style={{ padding: '0 12px' }}>
      <div style={{ ...formItemStyle }}>
        <Label style={{ ...labelStyle }}>
          {t('UserProfile_Language', 'Language', { type: 'tit', desc: '' })}:
        </Label>
        <Select
          onChange={(evt) => {
            const lng = evt.detail.selectedOption.dataset.id;
            /* istanbul ignore next */
            onDataPropChange(lng, 'language');
          }}
          id="language"
          data-testid="select-language"
          style={{ width: '100%' }}
        >
          {languages.map((language) => {
            return (
              <Option
                key={language.displayLanguageCode}
                data-id={language.displayLanguageCode}
                value={language.displayLanguageCode}
                selected={language.displayLanguageCode === data.language}
              >
                {language.language}
              </Option>
            );
          })}
        </Select>
      </div>
      <div style={{ ...formItemStyle }}>
        <Label style={{ ...labelStyle }}>
          {t('UserProfile_DateFormat', 'Date Format', { type: 'tit', desc: '' })}:
        </Label>
        <Select
          value={data.dateFormat}
          onChange={(evt) => {
            /* istanbul ignore next */
            onDataPropChange(evt.detail.selectedOption.dataset.id, 'dateFormat');
          }}
          id="dateFormat"
          style={{ width: '100%' }}
        >
          {DateFormatOptions.map((dateFormat, index) => {
            return (
              <Option
                key={dateFormat}
                data-id={dateFormat}
                value={dateFormat}
                selected={dateFormat === data.dateFormat}
              >
                {DateFormatTexts[index]}
              </Option>
            );
          })}
        </Select>
      </div>
      <div style={{ ...formItemStyle }}>
        <Label style={{ ...labelStyle }}>
          {t('UserProfile_TimeFormat', 'Time Format', { type: 'tit', desc: '' })}:
        </Label>
        <SegmentedButton
          disabled={false}
          onSelectionChange={(evt) => {
            /* istanbul ignore next */
            onDataPropChange(evt.currentTarget.selectedButton.id, 'timeFormat');
          }}
          selectedKey={data.timeFormat}
        >
          {TimeFormatOptions.map((timeFormat, index) => (
            <ToggleButton
              key={timeFormat}
              id={timeFormat}
              pressed={timeFormat === data.timeFormat}
              data-testid={`time-format-${timeFormat}`}
            >
              {t(TimeFormatTexts[index], TimeFormatTextsDefualts[index], {
                type: 'but',
                desc: '',
              })}
            </ToggleButton>
          ))}
        </SegmentedButton>
      </div>
      <div style={{ ...formItemStyle }}>
        <Label style={{ ...labelStyle }}>
          {t('UserProfile_TimeZone', 'Time Zone', { type: 'tit', desc: '' })}:
        </Label>
        <Select
          value={data.profileTimeZone}
          onChange={(evt) => {
            /* istanbul ignore next */
            onDataPropChange(evt.detail.selectedOption.dataset.id, 'profileTimeZone');
          }}
          id="timeZone"
          style={{ width: '220px' }}
        >
          {timezone?.map((timeZone, index) => {
            return (
              <Option
                key={timeZone.timezone}
                data-id={timeZone.timezone}
                value={timeZone.timezone}
                selected={timeZone.timezone === data.profileTimeZone}
              >
                {timeZone.displayName}
              </Option>
            );
          })}
        </Select>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, fetchTimeZone }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageAndRegion);
