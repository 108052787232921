export const devUrl =
  'https://wa-integration-poc.enable-now.cloud.sap/wa_cfg/retail_activity_optimization/WebAssistant.js';

export const prodUrl =
  'https://wa-integration-poc.enable-now.cloud.sap/wa_cfg/retail_activity_optimization/WebAssistant.js'; // https://webassistant.enable-now.cloud.sap/wa_cfg/intelligent_returns_productive/WebAssistant.js

// istanbul ignore next
export const getWebAssistantUrl = () => {
  switch (window.location.hostname) {
    // dev env
    case 'localhost':
    case 'ome.rao-sit.ome-dev.eurekacloud.io':
      return devUrl;
    default:
      return prodUrl;
  }
};
