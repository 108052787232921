/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import eureka from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';
import HeartBeatWebSocket from './HeartBeatWebSocket';
import { COMMON_GET_NOTIFICATIONS_SUCCESS, COMMON_GET_NOTIFICATIONS_FAILED } from './constants';

const { eventBus } = eurekaMgrs;
const { axiosCreate } = eureka.HttpWrapper;
const { getConfig } = eurekaMgrs.ConfigManager;

const injectInterceptor = (axiosInstance) => {
  axiosInstance.interceptors.request.use((config) => {
    const { tenantId, id } = getConfig('user') || {};
    config.headers['X-Tenant-ID'] = tenantId;
    config.headers['X-User-ID'] = id;
    return config;
  });
  return axiosInstance;
};

const notificationAxios = injectInterceptor(
  axiosCreate({
    baseURL: '/api/rm-notification-push/v1/messages',
  }).instance,
);
let __dispatch = null;
let __websocket = null;
let isMessageEnabled = false;

function fetchNotifications(args = {}, search = '', sortBy = '', pageSize = 10, pageCount = 1) {
  const promise = new Promise((resolve, reject) => {
    const doRequest = notificationAxios({
      url: '/',
      method: 'get',
      withCredentials: true,
    });
    doRequest.then(
      (res) => {
        __dispatch({
          type: COMMON_GET_NOTIFICATIONS_SUCCESS,
          data: (res && res.data) || [],
        });
        resolve(res);
      },
      (err) => {
        __dispatch({
          type: COMMON_GET_NOTIFICATIONS_FAILED,
          data: err,
        });
        reject(err);
      },
    );
  });
  return promise;
}

function onMessage(evt) {
  if (__dispatch) {
    fetchNotifications();
  }

  console.log(evt);

  // refresh deduction table and status
  if (evt && evt.data) {
    if (
      evt.data.businessObject &&
      evt.data.businessObject === 'Deduction' &&
      evt.data.changeAction === 'AutoFill'
    ) {
      // setInterval(() => {
      //   eventBus.emit('DeductionAutoFilled', '', evt.data);
      // }, 20 * 1000);
      eventBus.emit('DeductionAutoFilled', '', evt.data);
    }
  }
}

export function refreshNewMessages() {
  return setInterval(() => {
    if (isMessageEnabled) {
      fetchNotifications();
    }
  }, 60 * 1000);
}

export function startWebsocket() {
  return (dispatch) => {
    if (__dispatch) {
      return;
    }
    __dispatch = dispatch;
    if (!__websocket) {
      const protocol = window.location.protocol.startsWith('https') ? 'wss' : 'ws';
      const url = `${protocol}://${window.location.host}/api/rm-notification-push/`;
      __websocket = new HeartBeatWebSocket(url, onMessage).start();
    }
    fetchNotifications().then(
      () => {
        isMessageEnabled = true;
      },
      () => {
        isMessageEnabled = false;
        console.log('Message channel is supported, so will not start web socket');
      },
    );

    // listen close websocket of eventbus
    eventBus.on('close-websocket', (evtBody) => {
      console.log(evtBody);
      console.log('web socket close of eventbus');
      __websocket && __websocket._ws && __websocket._ws.close();
    });
  };
}

export function dismissNotification(notification) {
  return () =>
    new Promise((resolve, reject) => {
      const doRequest = notificationAxios({
        url: `/${notification.id}`,
        method: 'POST',
        withCredentials: true,
      });
      doRequest.then(
        (res) => {
          fetchNotifications();
          resolve(res);
        },
        (err) => {
          fetchNotifications();
          reject(err);
        },
      );
    });
}

export function dismissAllNotifications(notifications) {
  return () =>
    new Promise((resolve, reject) => {
      const doRequest = notificationAxios({
        url: '/',
        method: 'post',
        withCredentials: true,
        body: notifications.map((notification) => ({ ...notification, acknowledged: true })),
      });
      doRequest.then(
        (res) => {
          fetchNotifications();
          resolve(res);
        },
        (err) => {
          fetchNotifications();
          reject(err);
        },
      );
    });
}

export function deleteNotification(notification) {
  return (disptach) =>
    new Promise((resolve, reject) => {
      const doRequest = notificationAxios({
        url: `/${notification.id}`,
        method: 'delete',
        withCredentials: true,
      });
      doRequest.then(
        (res) => {
          fetchNotifications();
          resolve(res);
        },
        (err) => {
          fetchNotifications();
          reject(err);
        },
      );
    });
}

export function dismissAllNotification(notification) {
  return (disptach) =>
    new Promise((resolve, reject) => {
      const doRequest = notificationAxios({
        url: `/${notification.id}`,
        method: 'post',
        withCredentials: true,
        data: { ...notification, acknowledged: true },
      });
      doRequest.then(
        (res) => {
          fetchNotifications();
          resolve(res);
        },
        (err) => {
          fetchNotifications();
          reject(err);
        },
      );
    });
}

export function deleteAllNotification(notification) {
  return (disptach) =>
    new Promise((resolve, reject) => {
      const doRequest = notificationAxios({
        url: '/',
        method: 'delete',
        withCredentials: true,
        body: { ...notification, acknowledged: true },
      });
      doRequest.then(
        (res) => {
          fetchNotifications();
          resolve(res);
        },
        (err) => {
          fetchNotifications();
          reject(err);
        },
      );
    });
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notification: action.data,
      };
    case COMMON_GET_NOTIFICATIONS_FAILED:
      return {
        ...state,
        notification: [],
      };
    default:
      return state;
  }
}
