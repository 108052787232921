import { useState, useEffect } from 'react';
import history from '../history';
import { getWebAssistantUrl } from './waConfig';
import { setDocumentLang } from '../Utils';

export default ({ lang }) => {
  const [webAssistantLoaded, setWebAssistantLoaded] = useState(false);

  useEffect(() => {
    // istanbul ignore next
    const unlisten = history.listen(({ pathname }) => {
      window.onPathChange && window.onPathChange(pathname);
    });
    return unlisten;
  }, []);

  useEffect(() => {
    // setDocumentLang(document, lang);
  }, [lang]);

  useEffect(() => {
    const scriptId = 'web-assistant-script';
    if (document.getElementById(scriptId)) {
      return;
    }
    const script = document.createElement('script');

    script.id = scriptId;
    script.src = getWebAssistantUrl();
    script.defer = 'defer';

    // istanbul ignore next
    script.onload = () => {
      setWebAssistantLoaded(true);
    };
    console.log(`Web Assistant Script Url: ${getWebAssistantUrl()}`);
    document.body.appendChild(script);
  }, []);

  return { webAssistantLoaded };
};
