import React from 'react';
import { MessageStrip } from '@ui5/webcomponents-react/dist/MessageStrip';
import { MessageStripType } from '@ui5/webcomponents-react/dist/MessageStripType';
// import eureka from '@eureka/ui-managers'

import EurekaComponents from 'eureka'
// import { Panel } from '@ui5/webcomponents-react/dist/Panel';
// import { PanelAccessibleRoles } from '@ui5/webcomponents-react/dist/PanelAccessibleRoles';
// import { TitleLevel } from '@ui5/webcomponents-react/dist/TitleLevel';
const { useTranslation } = EurekaComponents.I18nProvider;

export default props => {
  const { t } = useTranslation();
  let errorType = 'general';
  const uuid = props.match.params.id;
  if (!uuid) {
    errorType = 'general';
  } else if (
    /[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}/gi.test(uuid)
  ) {
    //00001513-1212-EFDE-1523-785DEABCD122
    errorType = uuid;
  } else if ('user-not-found' === uuid.trim()) {
    errorType = 'user-not-found';
  } else if ('tenant-not-found' === uuid.trim()) {
    errorType = 'tenant-not-found';
  } else {
    errorType = uuid;
  }
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <div style={{ width: '300px', height: '200px' }}>
        <MessageStrip noCloseButton={true} noIcon={false} type={MessageStripType.Negative}>
          {t('Error_ErrorHappened')}
          <br />
          {t('Error_ErrorType')}: {errorType}
          <br />
          {t('Error_ErrorTips')}
        </MessageStrip>
      </div>
    </div>
  );
};
