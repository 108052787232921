/* eslint-disable operator-linebreak */
import React, { useState, useEffect, createRef } from 'react';

import { Dialog } from '@ui5/webcomponents-react/dist/Dialog';
import eurekaMgrs from '@eureka/ui-managers';

const { eventBus } = eurekaMgrs;
export const ShellDialog = () => {
  const dialogRef = createRef();
  const [properties, setProperties] = useState({
    showDialog: false,
    content: '',
    hideHeader: true,
    footer: null,
    stretch: false,
    title: '',
    clearPadding: true,
  });
  useEffect(() => {
    let dialogCurrent = dialogRef && dialogRef.current;
    eventBus.on('dialog-show-hide', (command) => {
      const { data } = command;
      if (data) {
        const ndata = { ...data };
        ndata.showDialog = data.showDialog;
        if (data.content) {
          ndata.content = typeof data.content === 'function' ? data.content() : data.content;
        }
        setProperties({
          ...properties,
          ...ndata,
        });
        if (ndata.showDialog) {
          dialogCurrent?.open && dialogCurrent.open();
        } else {
          dialogCurrent?.close && dialogCurrent.close();
        }
      }
    });
    let timer = 0;
    if (properties.clearPadding) {
      timer = setTimeout(() => {
        const container =
          dialogCurrent &&
          dialogCurrent?.getDomRef?.() &&
          dialogCurrent.getDomRef().querySelector('div.ui5-popup-scroll');
        container && (container.style.padding = 0);
      }, 0);
    }
    return () => {
      clearTimeout(timer);
      dialogCurrent = null;
      eventBus.detach('dialog-show-hide');
    };
  }, [dialogRef, properties]);
  return (
    <Dialog
      ref={dialogRef}
      open={properties.showDialog}
      className="logout-modal"
      onClose={() => {
        setProperties({
          ...properties,
          showDialog: false,
        });
        dialogRef.current.close();
      }}
      hideHeader={properties.hideHeader}
      stretch={properties.stretch}
      title={properties.title}
      footer={properties.footer}
    >
      {properties.content}
    </Dialog>
  );
};

/* istanbul ignore next */
export const closeShellDialog = () => {
  eventBus.emit('dialog-show-hide', '', {
    type: 'cancel',
    data: {
      showDialog: false,
    },
  });
};
