import eurekaMgrs from '@eureka/ui-managers';
import { getRandom } from '../features/common/Utils';
import { cloneDeep, get } from 'lodash';
const { getFeatureToggle } = eurekaMgrs.ConfigManager;

export const loadCSS = (dom, assetName, host, name, filePath, hash, callback) => {
  // load css styles
  // console.log(assetName, filePath, hash);

  const buildCssLink = (resolve, reject) => {
    const styleId = `${assetName}-style-${name.toLowerCase()}`;
    const style = dom.getElementById(styleId);
    if (!style) {
      const cssLink = dom.createElement('link');
      cssLink.id = styleId;
      cssLink.rel = 'stylesheet';
      cssLink.type = 'text/css';
      cssLink.crossOrigin = 'anonymous';
      cssLink.href = `${host}${filePath}?random=${getRandom()}`;
      if (hash) {
        cssLink.integrity = hash;
      }
      cssLink.onload = () => resolve();
      cssLink.onerror = (err) => reject(err);
      dom.head.appendChild(cssLink);
    } else {
      resolve();
    }
  };

  return new Promise((resolve, reject) => {
    buildCssLink(resolve, reject);
  });
};

export function getURLParam(url, name) {
  const searchParams = new URLSearchParams(url);
  return searchParams.get(name);
}

// 1Q = en-US-saptrc;
// 2Q = en-US-sappsd;
export const TestingLocales = {
  '1Q': 'en-US-saptrc',
  '2Q': 'en-US-sappsd',
};

export const constructPermissions = (rolePermission) => {
  const permissions = [];
  (rolePermission || [])
    .map((r) => r.permissionItem)
    .forEach((p) => {
      p.forEach((o) => {
        o.items.forEach((i) => {
          i.accesses.forEach((access) => {
            const authItem = `${i.authItemValue}.${access.accessName}`.toUpperCase();
            if (!permissions.includes(authItem)) {
              permissions.push(authItem);
            }
          });
        });
      });
    });

  return permissions;
};

export const setDocumentLang = (doc, langCode) => {
  doc && (doc.documentElement.lang = langCode);
};

/**
 * function to remove a MFE Component from Side-nav list if FF is disabled
 * @param {Array}: input array containing list of components/MFEs
 * @param {String}: name of components/MFE to be shown/hidden
 * @param {Boolean}: feature flag
 * @returns {Array}: output array containing list of components/MFEs to be displayed in side-nav
 * */
/* istanbul ignore next */
export function removeFeatureFlaggedComponent(componentList, componentName, featureFlag) {
  if (!componentName || featureFlag === undefined) {
    return componentList;
  }
  // if FF is disabled, remove component from the list and return remaining list
  if (!featureFlag) {
    componentList = cloneDeep(componentList);

    const components = componentName.split('.');
    let currentComponents = componentList;
    let index = -1;
    for (let i = 0; i < components.length; i++) {
      const subComponentName = components[i];
      index = currentComponents.findIndex((component) => component.id === subComponentName);
      if (index < 0) {
        return componentList;
      }
      if (i !== components.length - 1) {
        currentComponents = currentComponents[index].items;
      }
    }

    currentComponents.splice(index, 1);
  }
  return componentList;
}

export const handleWithFeatureFlagOME5081 = (config) => {
  const filtedListItems = config.components;
  const librariesIndex = filtedListItems.findIndex((item) => item.name === 'ome-libraries-ui');
  const featureFlagOME5081 = getFeatureToggle(
    'OME.5081.TableListView.Enhancement.ActivityListTemplate',
  );
  if (librariesIndex >= 0 && featureFlagOME5081) {
    const menuItems = filtedListItems[librariesIndex]?.config?.sidenav[0].items;
    const altListMenu = menuItems?.find((item) => item.id === 'activityListTemplate');
    altListMenu.router = '/alt/list';
  }
  return config;
};

/**
 * Return with a filted list items(componentList) for menu, which feature flags operation done
 * @param {} listItems
 */
export const filterWithFeatureFlags = (config) => {
  if (config?.components?.length > 0) {
    let newConfig = cloneDeep(config);
    newConfig = handleWithFeatureFlagOME5081(newConfig);
    return newConfig;
  }
  return config;
};
