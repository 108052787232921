import {
  List,
  ListMode,
  StandardListItem,
  Button,
  ButtonDesign,
  Title,
  TitleLevel,
} from '@ui5/webcomponents-react';
import React, { useEffect, useState } from 'react';
import {
  useTranslation,
  Spinner,
  getCurrentLanguage,
  setLanguage,
  configManagerSetLanguage,
  UtcMapping,
  MessageToast,
} from './eureka';
import { closeShellDialog } from '../../plugins/dialog.plugin';
import { fetchBasicSetup, fetchLanguages, fetchMe, patchMe } from './axios';
import LanguageAndRegion from './LanguageAndRegion';
import { TimeFormatOptions, TimeFormatTexts, TimeFormatTextsDefualts } from './options';
import { setDocumentLang } from '../../../common/Utils';
// import UserAccount from './UserAccount';

const initialData = {
  userName: '',
  pictureId: null,
  email: '',
  phone: '',
  language: '',
  dateFormat: '',
  timeFormat: '',
};

// const TimeZoneOptions = Object.keys(UtcMapping).map((key) => {
//   return { ...UtcMapping[key], key };
// });

// const getFullUserName = (userName) => {
//   if (userName && typeof userName === 'object') {
//     return userName.familyNameFirst
//       ? userName.lastName + ' ' + userName.firstName
//       : userName.firstName + ' ' + userName.lastName;
//   }
// };

const UserProfileLanguageAndRegion = 'user-profile_language_region';
const borderStyle = '1px solid #e8e8e8';

export default function UserProfile({ config, eventBus }) {
  const { t } = useTranslation('shell');
  const [isLoading, setLoading] = useState(false);
  const [currentItem, setCurrentItem] = useState(UserProfileLanguageAndRegion);
  const [data, setData] = useState({ ...initialData });
  const [originalData, setOriginalData] = useState({ ...initialData });
  const [languages, setLanguages] = useState([]);
  const [timeFormatIndex, setTimeFormatIndex] = useState(0);

  useEffect(() => {
    setTimeFormatIndex(TimeFormatOptions.indexOf(data.timeFormat));
  }, [data.timeFormat]);

  useEffect(() => {
    const getData = () => {
      setLoading(true);
      // return Promise.all([fetchMe(), fetchBasicSetup(), fetchLanguages(getCurrentLanguage())])
      return Promise.all([fetchMe(), fetchLanguages(getCurrentLanguage())])
        .then((results) => {
          const me = results[0].data;
          // const basicSetup = results[1].data;
          // if (me.userUpdateState === null) {
          //   me.language = basicSetup.language;
          //   me.dateFormat = basicSetup.dateFormat;
          //   me.timeFormat = basicSetup.timeFormat;
          //   me.timeZone = basicSetup.timeZone;
          //   me.userUpdateState = 1;
          // }
          setLanguage(me.language);
          setData({
            ...me,
          });
          setOriginalData({
            ...me,
          });
          setLanguages(results[1].data || []);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    getData();
  }, []);

  /* istanbul ignore next */
  // trigger re-render in mfes when user profile have updates
  const notifyOthers = (user, languageChanged) => {
    try {
      if (languageChanged) {
        // tell other mfes, language is changed, only update translations,
        // no reload in this case
        eventBus?.emit('i18n-update', '', user?.language || 'en-US');
        setDocumentLang(document, user?.language || 'en-US');
      }
      // tell other mfes, user profile is changed, mfe reloads
      eventBus?.emit('configuration-updated', 'user profile', {
        key: 'userProfile',
        data: user,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleCancel = () => {
    closeShellDialog();

    setData({
      ...originalData,
    });
  };

  /* istanbul ignore next */
  const onDataPropChange = (value, propName) => {
    if (propName.indexOf('.') > 0) {
      const [objName, subPropName] = propName.split('.');
      setData({
        ...data,
        [objName]: {
          ...data[objName],
          [subPropName]: value,
        },
      });
    } else {
      setData({
        ...data,
        [propName]: value,
      });
    }
  };

  /* istanbul ignore next */
  // TODO: Add test cases for save
  const handleSave = () => {
    const differ = {};
    let languageChanged = false;
    Object.keys(data).forEach((key) => {
      if (originalData[key] !== data[key]) {
        differ[key] = data[key];
        if (key === 'language') {
          languageChanged = true;
        }
      }
    });
    if (Object.keys(differ).length > 0) {
      let current = parseInt(data.userUpdateState);
      current = isNaN(current) ? 1 : current;
      differ.userUpdateState = current + 1;
    }
    setLoading(true);
    return (
      patchMe({ ...differ })
        .then((result) => {
          const user = result.data;
          console.log(user);
          setOriginalData({ ...user });
          setData({ ...user });
          notifyOthers(user, languageChanged);

          if (languageChanged) {
            setLanguage(data.language);
            configManagerSetLanguage(data.language);
          }
          MessageToast.success(
            t('UserProfile_Msg_UpdateSuccess', 'Update success', { type: 'msg', desc: '' }),
          );
          closeShellDialog();
        })
        // on language change, update language options list
        .then(() => {
          fetchLanguages(data.language).then((result) => {
            setLanguages(result.data || []);
          });
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setData({
            ...originalData,
          });
          MessageToast.error(
            t('UserProfile_Msg_UpdateFailed', 'Update fail', { type: 'msg', desc: '' }),
          );
          setLoading(false);
        })
    );
  };

  return (
    <div>
      <Title level={TitleLevel.H3} style={{ padding: '16px 24px', fontSize: '16px' }}>
        {t('UserProfile_ProfileSettings', 'Settings', { type: 'tit', desc: '' })}
      </Title>
      <div style={{ borderBottom: borderStyle, borderTop: borderStyle }}>
        <div
          style={{
            display: 'flex',
            width: '636px',
            height: '100%',
            minHeight: '330px',
            padding: 0,
          }}
        >
          <List
            style={{ width: '219px' }}
            mode={ListMode.SingleSelect}
            noDataText={t('UserProfile_Msg_NoDataAvailable', 'No data available', {
              type: 'msg',
              desc: '',
            })}
            onItemClick={(evt) => {
              setCurrentItem(evt.detail.item.id);
            }}
          >
            {/* <StandardListItem
              id="user-profile_account"
              icon="employee"
              selected={currentItem === 'user-profile_account'}
              description={getFullUserName(data.userName, data.familyNameFirst)}
            >
              {t('UserProfile_UserAccount')}
            </StandardListItem> */}
            <StandardListItem
              icon="globe"
              id={UserProfileLanguageAndRegion}
              selected={currentItem === UserProfileLanguageAndRegion}
              description={`${(
                (data.language && data.language.split('-')[0]) ||
                '...'
              ).toUpperCase()} | ${
                t(
                  TimeFormatTexts[timeFormatIndex] || '...',
                  TimeFormatTextsDefualts[timeFormatIndex],
                  { type: 'tit', desc: '' },
                ) || '...'
              }`}
            >
              {t('UserProfile_LanguageAndRegion', 'Language and Region', { type: 'mit', desc: '' })}
            </StandardListItem>
          </List>
          <section
            style={{
              flexGrow: 1,
              padding: '10px',
              display: 'flex',
              borderLeft: borderStyle,
            }}
          >
            {/* {currentItem === 'user-profile_account' && (
              <UserAccount
                data={data}
                timezones={TimeZoneOptions}
                onDataPropChange={onDataPropChange}
              />
            )} */}
            {currentItem === UserProfileLanguageAndRegion && (
              <LanguageAndRegion
                data={data}
                languages={languages}
                onDataPropChange={onDataPropChange}
              />
            )}
          </section>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: '60px',
        }}
      >
        <Button
          disabled={JSON.stringify(data) === JSON.stringify(originalData)}
          design={ButtonDesign.Emphasized}
          onClick={handleSave}
          data-testid="saveButton"
        >
          {t('UerProfile_Save', 'Save', { type: 'but', desc: '' })}
        </Button>
        <Button
          style={{
            marginLeft: '1rem',
            marginRight: '0.5rem',
          }}
          onClick={handleCancel}
          data-testid="cancelButton"
        >
          {t('UerProfile_Cancel', 'Cancel', { type: 'but', desc: '' })}
        </Button>
      </div>

      {isLoading && (
        <div
          style={{
            top: 0,
            width: '100%',
            height: '100%',
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            background: 'rgba(0, 0, 0, 0.01)',
            zIndex: 999,
          }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
}
