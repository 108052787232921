/* eslint-disable react/prop-types */
/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-tabs */
import { Button } from '@ui5/webcomponents-react/dist/Button';
import { NotificationListItem } from '@ui5/webcomponents-react/dist/NotificationListItem';
import { Priority } from '@ui5/webcomponents-react/dist/Priority';
import eureka from 'eureka';
import React from 'react';
import { createUseStyles } from 'react-jss';
import history from '../../common/history';

/**
Range	Key	Sample Output
0 to 44 seconds	s	a few seconds ago
unset	ss	44 seconds ago
45 to 89 seconds	m	a minute ago
90 seconds to 44 minutes	mm	2 minutes ago ... 44 minutes ago
45 to 89 minutes	h	an hour ago
90 minutes to 21 hours	hh	2 hours ago ... 21 hours ago
22 to 35 hours	d	a day ago
36 hours to 25 days	dd	2 days ago ... 25 days ago
26 to 45 days	M	a month ago
45 to 319 days	MM	2 months ago ... 10 months ago
320 to 547 days (1.5 years)	y	a year ago
548 days+	yy	2 years ago ... 20 years ago
 */

const { useTranslation } = eureka.I18nProvider;
const { fromUtcToZone } = eureka.DateManager;
const buttonStyle = {
  margin: '3px',
};
const Second = 1000;
const Minute = 60 * Second;
const Hour = 60 * Minute;
const Day = 24 * Hour;
const Year = 365 * Day;
const useStyles = createUseStyles({
  notificationStyle: {
    width: '400px',
    marginTop: '4px',
    marginBottom: '4px',
    borderRadius: '0.25rem',
    boxShadow: 'var(--sapContent_Shadow0)',
    '& > div > div': {
      boxShadow: 'none',
    },
  },
  wrapper: {
    display: 'flex',
  },
  contentWrapper: {
    width: '301px',
    marginBottom: '-20px',
  },
  content: {
    marginTop: '20px',
    whiteSpace: 'normal',
    color: 'var(--sapContent_LabelColor)',
  },
  footer: {
    textAlign: 'right',
    marginRight: '-48px',
  },
  indicator: {
    display: 'inline-block',
    margin: '4px 0',
    borderRadius: '0.25rem 0 0 0.25rem',
    width: '0.375rem',
    minWidth: '0.375rem',
    backgroundColor: 'var(--sapSuccessBorderColor)',
  },
  indicatorError: {
    display: 'inline-block',
    margin: '4px 0',
    borderRadius: '0.25rem 0 0 0.25rem',
    width: '0.375rem',
    minWidth: '0.375rem',
    backgroundColor: 'var(--sapErrorBorderColor)',
  },
});

export const reviewDocumentUpload = (notification, onCloseFunction) => {
  history.push(`/excel-extraction/${notification.data.businessObject.split('::')[1]}`);
  onCloseFunction(notification);
};

export const reviewApproval = (approvalId, claimId, onCloseFunction, notification) => {
  history.push(`/claims/details/${claimId}/approvals/${approvalId}`);
  onCloseFunction(notification);
};

export const viewDocumentDetail = (documentId, onCloseFunction, notification) => {
  history.push(`/document-library/details/${documentId}`);
  onCloseFunction(notification);
};
export const parseBusinessObject = (notification) => {
  let fileName;
  let documentId;
  try {
    fileName = JSON.parse(notification.data.businessObject).originalFileName;
    documentId = JSON.parse(notification.data.businessObject).documentId;
  } catch {
    fileName = '';
    documentId = '';
  }
  return { fileName, documentId };
};

export const generateFromNowStr = (totalTime, t) => {
  let fromNow = '';
  if (totalTime > Year) {
    const years = parseInt(totalTime / Year, 10);
    fromNow += `${years} ${t('Measure_Year')}`;
    totalTime %= Year;
  }
  if (totalTime > Day) {
    const days = parseInt(totalTime / Day, 10);
    fromNow += ` ${days} ${t('Measure_Day')}`;
    totalTime %= Day;
  }
  if (totalTime > Hour) {
    const hours = parseInt(totalTime / Hour, 10);
    fromNow += ` ${hours} ${t('Measure_Hour')}`;
    totalTime %= Hour;
  }
  if (totalTime > Minute) {
    const minutes = parseInt(totalTime / Minute, 10);
    fromNow += ` ${minutes} ${t('Measure_Minute')}`;
  }
  return fromNow;
};

export default function CustomNotification({ notification, onCloseFunction, settings }) {
  const { t } = useTranslation();

  const classes = useStyles();

  const zoneDate = fromUtcToZone(
    notification.receivedTime,
    settings.timeZone,
    `${settings.dateFormat} HH:mm:ss`,
  );
  const fromNow = `${generateFromNowStr(
    new Date().getTime() - new Date(zoneDate).getTime(),
    t,
  )} ${t('Measure_Suffix_Ago')}`;

  const datetime = (
    <div style={{ marginTop: '5px', color: 'var(--sapContent_LabelColor)' }}>{fromNow}</div>
  );
  if (notification?.data?.businessObject?.startsWith('USER_MANAGEMENT_RESPONSIBILITIES')) {
    return (
      <div className={classes.wrapper}>
        <div className={classes.indicator} />
        <NotificationListItem
          className={classes.notificationStyle}
          heading={t('Notification_Responsibilities')}
          footnotes={datetime}
          priority={Priority.Low}
          showClose
          onClose={() => onCloseFunction(notification)}
          wrap
          read={false}
        >
          <div className={classes.contentWrapper}>
            <div className={classes.content}>{notification.data.message}</div>
            <div className={classes.footer}>
              <div style={{ height: '34px' }} design="Positive" />
            </div>
          </div>
        </NotificationListItem>
      </div>
    );
  }
  return (
    <div className={classes.wrapper}>
      <div className={classes.indicator} />
      <NotificationListItem
        className={classes.notificationStyle}
        heading={t('Notification_Other')}
        priority={Priority.Low}
        showClose
        onClose={() => onCloseFunction(notification)}
        wrap
        read={false}
      >
        <div className={classes.contentWrapper}>
          <div className={classes.content}>{notification.data.message}</div>
          {datetime}
          <div className={classes.footer}>
            <Button style={buttonStyle} design="Positive" disabled>
              {t('Notification_ViewBtn')}
            </Button>
          </div>
        </div>
      </NotificationListItem>
    </div>
  );
}
