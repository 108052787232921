/* eslint-disable react/jsx-filename-extension */
import { setCustomElementsScopingSuffix } from '@ui5/webcomponents-base/dist/CustomElementsScope';
import { setSuffix } from '@ui5/webcomponents-base/dist/renderer/LitRenderer';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import axiosWrapper from './axios/axiosWrapper';
import './styles/index.less';
import eureka from 'eureka';

const { setLanguage } = eureka.I18nProvider;

/* istanbul ignore next */
if (process.env.NODE_ENV !== 'test') {
  setSuffix(process.env.APP_NAME);
  setCustomElementsScopingSuffix(process.env.APP_NAME);
}

/* istanbul ignore next */
eventBus.on('i18n-update', (langCode) => {
  setLanguage(langCode);
});

ReactDOM.render(<App {...axiosWrapper()} />, document.getElementById('irss-app'));
