import React from 'react';
import { ShellBarItem } from '@ui5/webcomponents-react';
import eureka from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';

const { useTranslation } = eureka.I18nProvider;
const { getConfig } = eurekaMgrs.ConfigManager;

/* istanbul ignore next */
const WebAssistantHelpButton = () => {
  const { t } = useTranslation();

  return (
    <>
      {getConfig('waLoaded') && (
        <ShellBarItem
          text={t('ShellBar_Help_Button')}
          tooltip={t('ShellBar_Help_Button')}
          onItemClick={null}
          icon="sys-help"
        />
      )}
    </>
  );
};

export default WebAssistantHelpButton;
